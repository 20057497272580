import React from "react";
import { httpPost } from "../../http";
import {
  message,
  Modal,
  Button,
  Form,
  Input,
  Upload,
  Row,
  Col,
} from "antd";
import defaultAvatar from "../../images/photo.jpg";
const FormItem = Form.Item;

class AddFormContainer extends React.Component {
  state = {
    photo_url: "",
  };

  render() {
    const that = this;
    const {
      modalVisibleAdd,
      form,
      handleModalVisibleAdd,
      getWorkersList,
      modalTitleAdd,
      initData,
      type,
    } = this.props;

    const avatarURL = defaultAvatar;
    const okHandle = () => {
      form.validateFields((err, fieldsValue) => {
        if (err) return;
        
        if (initData.id) {
          let submit_value = {
            id: initData.id,
            photo: that.state.photo_url ? that.state.photo_url : initData.photo,
            ...fieldsValue,
          };
          httpPost("/api/v1/worker/update_worker", JSON.stringify(submit_value))
            .then((res) => {
              return res.json();
            })
            .then((data) => {
              if (data.code === 0) {
                that.setState({
                  photo_url: "",
                });
                handleModalVisibleAdd();
                getWorkersList(type);

                form.resetFields();
              } else {
                message.error(data.msg);
              }
            })
            .catch((err) => {
              message.error("Interface error:add_worker");
            });
        } else {
          let submit_value = {
            photo: that.state.photo_url ? that.state.photo_url : initData.photo,
            ...fieldsValue,
          };
          httpPost("/api/v1/worker/add_worker", JSON.stringify(submit_value))
            .then((res) => {
              return res.json();
            })
            .then((data) => {
              if (data.code === 0) {
                that.setState({
                  photo_url: "",
                });
                handleModalVisibleAdd();
                getWorkersList(type);
                form.resetFields();
              } else {
                message.error(data.msg);
              }
            })
            .catch((err) => {
              message.error("Interface error:add_worker");
            });
        }
        
      });
    };
    const uploadProps = {
      name: "file",
      action: "/api/v1/worker/upload_worker_photo",
      headers: {
        authorization: "authorization-text",
      },
      onChange(info) {
        console.log(info);
        if (info.file.status === "done") {
          let response = info.file.response.data;
          console.log(response.path + response.filename);
          that.setState({
            photo_url: response.path + response.filename,
          });
          message.success(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status === "error") {
          message.error(`${info.file.name} file upload failed.`);
        }
      },
    };
    return (
      <Modal
        destroyOnClose
        title={modalTitleAdd}
        visible={modalVisibleAdd}
        okText="Save"
        onOk={okHandle}
        width={800}
        onCancel={() => handleModalVisibleAdd()}
      >
        <Form layout="vertical" hideRequiredMark>
          <Row gutter={16}>
            <Col span={12}>
              <FormItem label="Name">
                {form.getFieldDecorator("name", {
                  rules: [
                    {
                      required: true,
                      message: "Please input name.",
                    },
                  ],
                  initialValue: initData.name,
                })(<Input />)}
              </FormItem>
              <FormItem label="NRIC/Fin no">
                {form.getFieldDecorator("nric", {
                  rules: [
                    {
                      message: "Please input nric/fin no.",
                    },
                  ],
                  initialValue: initData.nric,
                })(<Input />)}
              </FormItem>
              <FormItem label="Package Pass No">
                {form.getFieldDecorator("package_pass_no", {
                  rules: [
                    {
                      message: "Please input package pass no.",
                    },
                  ],
                  initialValue: initData.package_pass_no,
                })(<Input />)}
              </FormItem>
              
            </Col>
           
            <Col span={12}>
              <FormItem label="CSN Number">
                {form.getFieldDecorator("csn_number", {
                  rules: [
                    {
                      required: true,
                      message: "Please input csn number.",
                    },
                    {
                      min: 10,
                      message: "Please input 10 digits.",
                    },
                  ],
                  initialValue: initData.csn_number,
                })(<Input />)}
              </FormItem>
              <FormItem label="Company">
                {form.getFieldDecorator("company", {
                  rules: [
                    {
                      message: "Please input company.",
                    },
                  ],
                  initialValue: initData.company,
                })(<Input />)}
              </FormItem>
              <FormItem label="Contract Title">
                {form.getFieldDecorator("contract_title", {
                  rules: [
                    {
                      message: "Please input contract title.",
                    },
                  ],
                  initialValue: initData.contract_title,
                })(<Input />)}
              </FormItem>
              <FormItem label="Avatar" style={{display: 'none'}}>
                <div>
                  <div className="avatar">
                    <img
                      style={{
                        marginRight: "30px",
                        borderRadius: "50%",
                        width: "150px",
                        height: "150px",
                      }}
                      src={this.state.photo_url || initData.photo || avatarURL}
                      alt="avatar"
                    />
                    <Upload {...uploadProps}>
                      <div className="button_view">
                        <Button icon="upload"> Change avatar </Button>
                      </div>
                    </Upload>
                  </div>
                </div>
              </FormItem>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
}
const AddForm = Form.create()(AddFormContainer);
export default AddForm;
