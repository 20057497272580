import React from "react";
import { Row, Col, Card, Button, message, Input } from "antd";
import { httpGet, httpPost } from "../../http";
import { withRouter } from "react-router-dom";
import LinksIcon from "../../images/links-icon.png"

import "./bind.css"


class Bind extends React.Component {
  state = {
    profileData: [],
    inputProfile: '',
    signProfile: false,

    deviceData: [],
    inputDevice: '',
    signDevice: false,

    loading: false,
    bindObj: {}
  };

  componentDidMount() {
    this.getWorkersList()
    this.getDevicesList()
    this.refs.profile.focus()
  };

  getWorkersList = (type) => {
    httpGet("/api/v1/worker/get_workers_list")
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.code === 0) {
          this.setState({
            profileData: data.data,
          });
        }
      })
      .catch((err) => {
        message.error("Interface error: get_workers_list");
      });
  };
  getDevicesList = () => {
    httpGet("/api/v1/device/get_idle_devices")
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.code === 0) {
          this.setState({
            deviceData: data.data,
          });
        }
      })
      .catch((err) => {
        message.error("Interface error: get_device_list");
      });
  };
  filterProfile = (value) => {
    let arr = []
    arr = this.state.profileData.filter(item => {
      return String(item.csn_number) === value
    })
    if (arr.length > 0) {
      this.setState({ 
        signProfile: true,
        inputDevice: arr[0].device_id && arr[0].device_id,
        bindObj: arr[0],
      });
      this.refs.device.focus()
    } else {
      this.setState({ 
        signProfile: false,
        bindObj: {},
      });
      this.refs.profile.focus()
      
      message.error("Please input right csn_number.");
      
      
    }
  }

  onChangeProfile = e => { 
    // console.log("=============change", e.target.value)
    let value = e.target.value

    this.setState({
      inputProfile: value
    })
    if (value === "") {
      this.setState({
        signProfile: false,
        bindObj: {}
      })
      return
    } 

    if (value.length >= 10) {
      this.filterProfile(value)
    }
    
  };
  
  onEnterProfile = e => {
    // console.log("===========Press enter",e.target.value)
    let value = e.target.value

    this.filterProfile(value)
    
  };
  
  filterIdleDevice = (value) => {
    let arr = []
    arr = this.state.deviceData.filter(item => {
      return item.device_id === value
    })
    if (arr.length > 0) {
      this.setState({ 
        signDevice: true,
      });
    } else {
      this.setState({ 
        signDevice: false,
      });
      this.refs.device.focus()
      
      message.error("Please input idle device.");
      
      
    }
  }
  onChangeDevice = e => { 
    // console.log("=============change device", e.target.value)
    let value = e.target.value

    this.setState({
      inputDevice: value
    })
    if (value.length >= 10) {
      let arr = []
      arr = this.state.profileData.filter(item => {
        return String(item.device_id) === value
      })
      if (arr.length > 0) {
        this.setState({ 
          signProfile: true,
          signDevice: true,
          inputProfile: arr[0].csn_number && arr[0].csn_number,
          bindObj: arr[0]
        });
      } else {
        this.filterIdleDevice(value)
      }
    }
    
  };
  onEnterDevice = e => {
    // console.log("===========Press enter device",e.target.value)
    let value = e.target.value

    if (this.state.bindObj && this.state.bindObj.device_id) {
      return
    }

    this.filterDevice(value)
    
  };
  
  bind = () => {
    // check param
    if (!this.state.bindObj.id) {
      this.refs.profile.focus()
      message.error("Please input csn number");
      return
    }
    if (!this.state.signProfile) {
      this.refs.profile.focus()
      message.error("Please input right csn number");
      return
    }
    if (!this.state.inputDevice) {
      this.refs.device.focus()
      message.error("Please input device id");
      return
    }
    if (!this.state.signDevice) {
      this.refs.device.focus()
      message.error("Please input right device");
      return
    }
    let submit_value = {
      worker_id: this.state.bindObj.id,
      device_id: this.state.inputDevice
    };
    httpPost("/api/v1/device/bind", JSON.stringify(submit_value))
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.code === 0) {
          message.success( this.state.bindObj.csn_number + " bound " + this.state.inputDevice + " success.");
          // init data
          this.initData()
          this.refs.profile.focus()
        } else {
          message.error(data.msg);
        }
      })
      .catch((err) => {
        message.error("Interface error: bind device");
      });
  }
  unbind = ()=> {
    // check param
    if (!this.state.bindObj.id) {
      message.error("Please input csn number");
      return
    }
    
    if (!this.state.signProfile) {
      this.refs.profile.focus()
      message.error("Please input right profile");
      return
    }

    let submit_value = {
      device_id: this.state.inputDevice
    };
    httpPost("/api/v1/device/unbind", JSON.stringify(submit_value))
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.code === 0) {
          message.success( this.state.bindObj.csn_number + " unbound success.");
          // init data
          this.initData()
          this.refs.device.focus()
        } else {
          message.error(data.msg);
        }
      })
      .catch((err) => {
        message.error("Interface error: unbind device");
      });
  }
  initData = () => {
    this.getDevicesList()
    this.getWorkersList()
    this.setState({
      inputProfile: '',
      signProfile: false,
      inputDevice: '',
      signDevice: false,
      loading: false,
      bindObj: {}
    })
    
  }
  render() {
    
    return (
      <div className="box">
        <Row gutter={16}>
          <Col span={8} offset={1}>
            <Card title="Profile" style={{height: 200}}>
              <Row>
                <Col span={6}>
                  <span className="label">CSN Number:</span>
                </Col>
                <Col span={18}>
                  <Input 
                    ref="profile"
                    value={this.state.inputProfile}
                    onChange={this.onChangeProfile}
                    // onPressEnter={this.onEnterProfile}
                    allowClear/>
                </Col>
              </Row>
              
            </Card>
          </Col>
          <Col span={1} style={{textAlign: 'center'}}>
            <img src={ LinksIcon } width="30" alt="" style={{marginTop:80}}/>
          </Col>
          <Col span={8}>
            <Card title="Device" style={{height: 200}}>
              <Row>
                <Col span={4}>
                  <span className="label"> Device ID:</span>
                </Col>
                <Col span={20}>
                  <Input 
                    ref="device"
                    value={this.state.inputDevice}
                    onChange={this.onChangeDevice}
                    // onPressEnter={this.onEnterDevice}
                    allowClear/>
                </Col>
              </Row>
              
              <br />
              <br />
              <Button 
                type="primary" 
                className={this.state.bindObj && this.state.bindObj.device_id ? 'hide': ''}
                onClick={() => this.bind()}>
                Bind
              </Button>
              <Button 
                type="danger" 
                className={this.state.bindObj && this.state.bindObj.device_id ? '': 'hide'}
                onClick={() => this.unbind()}>
                  Unbind
              </Button>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withRouter(Bind);
