import React from "react";
import { Table, message, Button } from "antd";
import { httpGet } from "../../http";
import { withRouter } from "react-router-dom";

class Alert extends React.Component {
  state = {
    data: [],
    pageSize: 10
  };

  componentDidMount() {
    this.getWorkersAlert(0);
  }

  getWorkersAlert = () => {
    httpGet("/api/v1/alert/get_alert_list")
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        this.setState({
          data: data.data,
        });
      })
      .catch((err) => {
        message.error("Interface error:get_alert_list");
      });
  };

  viewPosition = (item) => {
    localStorage.setItem("location", JSON.stringify(item));
    this.props.history.push("./location");
    sessionStorage.setItem("alertCurrentPage", this.state.current);
  };
  
  onShowSizeChange = (current, size) => {
    this.setState({
      pageSize: size
    })
  }
  columns = [
    {
      title: "Time",
      align: "center",
      dataIndex: "time",
      key: "time",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Company",
      dataIndex: "company",
      key: "company",
    },
    {
      title: "Event",
      dataIndex: "message",
      key: "message",
    },
    {
      title: "Action",
      dataIndex: "id",
      align: "center",
      key: "id",
      render: (text, record) => (
        <span>
          <Button type="link" onClick={() => this.viewPosition(record)}>View Position</Button>
        </span>
      ),
    },
  ];

  render() {
    return (
      <div className="box">
        <Table
          rowKey={(record) => record.id}
          columns={this.columns}
          dataSource={this.state.data}
          pagination={{
            current: this.state.current,
            onChange: (Page) => {
              this.setState({
                current: Page,
              });
            },
            pageSize: this.state.pageSize,
              showSizeChanger: true,
              onShowSizeChange: this.onShowSizeChange
          }}
        ></Table>
      </div>
    );
  }
}

export default withRouter(Alert);
