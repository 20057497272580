import React from "react";
import { Drawer, Avatar, Descriptions } from "antd";
import "./Home.scss";

import defaultAvatar from "../../images/photo2.png";

class Person extends React.Component {
  state = {
    collapse: false,
  };

  componentWillReceiveProps(nextProps) {
    this.setState({
      collapse: nextProps.show,
    });
  }

  onClose = () => {
    this.setState({
      collapse: false,
    });
  };

  onSelectChange = (selectedRowKeys) => {
    console.log(selectedRowKeys);
    this.setState({ selectedRowKeys });
  };

  render() {
    const { collapse } = this.state;
    const { data } = this.props;
    return (
      <div>
        <Drawer width={360} onClose={this.onClose} visible={collapse}>
          <div className="personAvatar">
            <Avatar src={data.photo || defaultAvatar} size={128} />
          </div>
          <div className="personInfo">
            <Descriptions bordered size="small" column={1}>
              <Descriptions.Item label="Name">{data.name}</Descriptions.Item>
              <Descriptions.Item label="NRIC/Fin no">{data.nric}</Descriptions.Item>
              <Descriptions.Item label="Package Pass No">{data.package_pass_no}</Descriptions.Item>
              <Descriptions.Item label="Company">{data.company}</Descriptions.Item>
              <Descriptions.Item label="CSN Number">{data.csn_number}</Descriptions.Item>
              <Descriptions.Item label="Device ID">{data.device_id}</Descriptions.Item>
              <Descriptions.Item label="In Time">{data.in_time}</Descriptions.Item>
            </Descriptions>
          </div>
        </Drawer>
      </div>
    );
  }
}

export default Person;
