import React from "react";
import { Button, Form, Input, message } from "antd";
import { withRouter } from "react-router-dom";
import "./User.scss";
import { httpPost } from "../../http";
import loginTitle from "../../images/login_title.png";

const LoginForm = Form.create()((props) => {
  const { form } = props;
  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields((err, fieldsValue) => {
      if (err) return;
      httpPost("/auth/login", JSON.stringify(fieldsValue))
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          console.log(data);
          if (data.status === "ok") {
            sessionStorage.setItem("isLogin", "1");
            sessionStorage.setItem("username", data.name);
            props.history.push("./home");
          } else {
            message.error("Account or password error");
          }
        })
        .catch((err) => {
          message.error("Interface error:login");
        });
    });
  };

  return (
    <div className="login">
      <div className="login-title">
        <img src={loginTitle} alt="" />
      </div>
      <Form onSubmit={handleSubmit} className="login-form">
        <Form.Item>
          {form.getFieldDecorator("username", {
            rules: [{ required: true, message: "Please input your username!" }],
          })(<Input type="text" placeholder="Username" />)}
        </Form.Item>
        <Form.Item>
          {form.getFieldDecorator("password", {
            rules: [{ required: true, message: "Please input your Password!" }],
          })(<Input type="password" placeholder="Password" />)}
        </Form.Item>
        <Form.Item style={{ textAlign: "center" }}>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            Login
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
});

class Login extends React.Component {
  render() {
    return (
      <div>
        <LoginForm history={this.props.history} />
      </div>
    );
  }
}

export default withRouter(Login);
