import React from "react";
import { httpPost, httpGet, httpDelete } from "../../http";
import {
  Popconfirm,
  Button,
  Divider,
  Table,
  Form,
  Row,
  Col,
  Input,
  message,
} from "antd";
const FormItem = Form.Item;

class UuidContainer extends React.Component {
  state = {
    data: [],
    editData: {
      id: "",
      uuid: "",
      location: [],
      remarks: "",
    },
    btnText: "Add",
  };

  componentDidMount() {
    this.getUuid();
  }

  getUuid = () => {
    httpGet("/api/v1/beacon/beacons_list")
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        const uuidlist = data.data;
        this.setState({
          data: uuidlist,
        });
      })
      .catch((err) => {
        message.error("Interface error: beacons_list");
      });
  };

  delete_confirm = (id) => {
    httpDelete("/api/v1/beacon/delete_beacon", JSON.stringify({ id: id }))
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.code === 0) {
          message.info("Delete Success!");
          this.getUuid();
        }
      })
      .catch((err) => {
        message.error("Interface error: delete beacon");
      });
  };

  edit = (data) => {
    this.setState({
      editData: data,
      btnText: "Change",
    });
  };

  columns = [
    {
      title: "Uuid",
      align: "center",
      dataIndex: "uuid",
      key: "uuid",
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      render: (text, record) => <span>{text[0] + "," + text[1]}</span>,
    },
    {
      title: "Remarks",
      align: "center",
      dataIndex: "remarks",
      key: "remarks",
    },
    {
      title: "Action",
      dataIndex: "id",
      align: "center",
      key: "id",
      render: (text, record) => (
        <span>
          <Popconfirm
            placement="top"
            title="Are you sure to delete this task?"
            onConfirm={() => this.delete_confirm(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link">Delete</Button>
          </Popconfirm>
          <Divider type="vertical" />
          <Button type="link" onClick={() => this.edit(record)}>
            Edit
          </Button>
        </span>
      ),
    },
  ];

  render() {
    const { form } = this.props;

    const okCancel = () => {
      this.setState({
        editData: {
          id: "",
          uuid: "",
          location: [],
          remarks: "",
        },
        btnText: "Add",
      });
    };
    const okHandle = () => {
      form.validateFields((err, fieldsValue) => {
        if (err) return;
        form.resetFields();
        let submit_value;
        console.log(fieldsValue);
        if (this.state.btnText === "Add") {
          submit_value = {
            ...fieldsValue,
            location: fieldsValue.location.split(","),
          };
        } else {
          if (typeof fieldsValue.location === "string") {
            fieldsValue.location = fieldsValue.location.split(",");
          }
          submit_value = {
            id: this.state.editData.id,
            uuid: this.state.editData.uuid,
            remarks: this.state.editData.remarks,
            location: this.state.location,
            ...fieldsValue,
          };
        }

        httpPost("/api/v1/beacon/add_beacon", JSON.stringify(submit_value))
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            if (data.code === 0) {
              this.getUuid();
            } else {
              message.error(data.msg);
            }
            okCancel();
          });
      });
    };

    return (
      <div className="box uuid">
        <Form layout="vertical">
          <Row gutter={16}>
            <Col span={5}>
              <FormItem label="Uuid">
                {form.getFieldDecorator("uuid", {
                  rules: [
                    {
                      required: true,
                      message: "Please input your Uuid!",
                    },
                  ],
                  initialValue: this.state.editData.uuid,
                })(<Input />)}
              </FormItem>
            </Col>
            <Col span={5}>
              <FormItem label="Location">
                {form.getFieldDecorator("location", {
                  rules: [
                    {
                      required: true,
                      message: "Please input your Location!",
                    },
                  ],
                  initialValue: this.state.editData.location,
                })(<Input />)}
              </FormItem>
            </Col>
            <Col span={5}>
              <FormItem label="Remarks">
                {form.getFieldDecorator("remarks", {
                  rules: [
                    {
                      required: true,
                      message: "Please input your Remarks!",
                    },
                  ],
                  initialValue: this.state.editData.remarks,
                })(<Input />)}
              </FormItem>
            </Col>
            <Col span={5}>
              <Button type="primary" className="btn" onClick={() => okHandle()}>
                {this.state.btnText}
              </Button>
              {this.state.btnText === "Change" ? (
                <Button type="primary" className="btn" onClick={() => okCancel()}>
                  Cancel
                </Button>
              ) : null}
            </Col>
          </Row>
        </Form>
        <Table
          rowKey={(record) => record.id}
          columns={this.columns}
          dataSource={this.state.data}
        ></Table>
      </div>
    );
  }
}
const Uuid = Form.create()(UuidContainer);
export default Uuid;
