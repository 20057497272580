import React, { Component } from "react";
import { message, Select, Alert, Icon } from "antd";
import userIcon from "../../images/po.png";
import ReactAudioPlayer from 'react-audio-player';
import alertMp3 from "../../images/alert.mp3";
import "./Home.scss";
import Person from "./DrawerPerson.js";
import { httpGet } from "../../http";
import mutePng from "../../images/mute.png"
import soundPng from "../../images/sound.png"

import moment from 'moment'
const dateFormat = 'MM-DD HH:mm'

const { Option } = Select;
let map = {};

export default class Home extends Component {
  state = {
    drawerPersonShow: false,
    currentMarker: {},
    marker_show: [],
    marker_show_onsite: [],
    all_marker: [],
    alertData: [],
    muteSign: true,
  };

  componentDidMount() {
    if (!window.google) {
      message.error("Internet error:google map");
      return;
    }
    //初始化地图
    let mapProp = {
      center: new window.google.maps.LatLng(1.354094, 103.991699),
      // center: new window.google.maps.LatLng(24.873507, 118.679722),
      zoom: 16,
      disableDefaultUI: true,
      heading: -67,
      mapId: "a3c5a60ff715a444",
      mapTypeId: window.google.maps.MapTypeId.HYBRID,
    };
    map = new window.google.maps.Map(this.refs.map, mapProp);
    this.getGenfence();
    this.getWorkersList();
    this.getOutFenceAlerts();
    this.timer = setInterval(() => {
      this.getWorkersList();
      this.getOutFenceAlerts()
    }, 10000);
    this.initMute()
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  getWorkersList = () => {
    httpGet("/api/v1/worker/get_workers_list")
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        let marker_show = data.data.filter((item, index) => {
          return item.show === 1;
        });
        let marker_show_onsite = data.data.filter((item, index) => {
          return item.onsite;
        });
        
        this.setState({
          marker_show,
          marker_show_onsite,
        });
        this.renderMarker(marker_show_onsite);
      })
      .catch((err) => {
        message.error("Interface error:get_workers_list");
      });
  };

  renderMarker = (data) => {
    //地图标记
    this.clearMaker();
    this.setState({
      drawerPersonShow: false,
    });
    const all_marker = [];
    data.forEach((value, index, arr) => {
      let marker = new window.google.maps.Marker({
        position: new window.google.maps.LatLng(
          value.latitude,
          value.longitude
        ),
        icon: userIcon,
      });
      marker.setMap(map);
      window.google.maps.event.addListener(marker, "click", () => {
        this.setState({
          drawerPersonShow: true,
          currentMarker: value,
        });
      });
      all_marker.push(marker);
      this.setState({
        all_marker,
      });
    });
  };

  getGenfence = () => {
    httpGet("/api/v1/fence/get_geofence_list")
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        this.renderGenfence(data.data.geo_points);
      })
      .catch((err) => {
        message.error("Interface error:get_geofence_list");
      });
  };

  clearMaker = () => {
    this.state.all_marker.forEach((item, index) => {
      item.setMap(null);
    });
  };

  renderGenfence = (genfence_arr) => {
    //地图围栏
    for (let k = 0; k < genfence_arr.length; k++) {
      if (k === (genfence_arr.length - 1)) {
        continue
      }
      let paths = [];
      let genfence = genfence_arr[k];
    
      for (let i = 0; i < genfence.length; i++) {
        let point = genfence[i];
        paths.push(new window.google.maps.LatLng(point.G, point.K));
      }
      let flightPath = new window.google.maps.Polygon({
        path: paths,
        strokeColor: "#f00",
        strokeOpacity: 0,
        strokeWeight: 2,
        fillColor: "#f00",
        fillOpacity: 0.4,
      });
      flightPath.setMap(map);
    };
  };

  onSearch = (value) => {
    this.clearMaker();
    this.setState({
      drawerPersonShow: false,
    });
    let marker_search = this.state.marker_show_onsite.filter((item, index) => {
      return item.name.toUpperCase() === value.toUpperCase();
    });
    if (marker_search.length > 0) {
      map.setCenter(
        new window.google.maps.LatLng(
          marker_search[0].latitude,
          marker_search[0].longitude
        )
      );
      this.renderMarker(marker_search);
    } else {
      map.setCenter(new window.google.maps.LatLng(1.354094, 103.991699));
      this.renderMarker(this.state.marker_show_onsite);
    }
  };
  getOutFenceAlerts = () => {
    httpGet("/api/v1/alert/get_out_fence_alerts")
    // httpGet("/api/v1/alert/get_alert_list")
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        this.setState({
          alertData: data.data,
        });
        if (this.state.alertData.length > 0 && !this.state.muteSign) {   
          this.rap.audioEl.current.play()
        }
      })
      .catch((err) => {
        message.error("Interface error: get_out_fence_alerts");
      });
  };
  onAlert = (item) => {
    httpGet("/api/v1/alert/view?id=" + item.id)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        localStorage.setItem("location", JSON.stringify(item));
        this.props.history.push("./location");
      })
      .catch((err) => {
        message.error("Interface error: get_alert_view");
      });
    
  }
  onClose = (item) => {
    httpGet("/api/v1/alert/view?id=" + item.id)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        this.getOutFenceAlerts()
      })
      .catch((err) => {
        message.error("Interface error: get_alert_view");
      });
  }
  initMute = () => {
    let _mute = sessionStorage.getItem("muteSign")
    if (_mute) {
      this.setState({
        muteSign: _mute === "true"
      })
    } else {
      sessionStorage.setItem("muteSign", this.state.muteSign)
    }
  }
  handleMute = () => {
    this.setState({
      muteSign: !this.state.muteSign
    })

    sessionStorage.setItem("muteSign", !this.state.muteSign)
  }
  formateAlertMsg = (item) => {
    const time = moment(item.time).format(dateFormat)
    const name = item.name.length > 10 ? item.name.slice(0,11) + "***" : item.name 
    const company = item.company.length > 15 ? item.company.slice(0,11) + "***" : item.company 
    return  `${time} ${name}, ${company} : ${item.message}`
  }
  render() {

    return (
      <div className="home">
        <div className="info">
          <span>
            Total Personnel on site:&nbsp;
            <b>{this.state.marker_show_onsite.length}</b>
          </span>
          <span className="search">
            <Select
              showSearch
              style={{ width: 200 }}
              onChange={this.onSearch}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option key={0} value="">
                All
              </Option>
              {this.state.marker_show_onsite.map((item) => (
                <Option key={item.id} value={item.name}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </span>
          <span className="mute">
            <img src={this.state.muteSign ? mutePng : soundPng } alt="" onClick={() => this.handleMute()} />
          </span>
        </div>
        <div
          id="googleMap"
          ref="map"
          style={{ width: "100%", height: "calc(100vh - 105px)" }}
        ></div>
        <Person
          show={this.state.drawerPersonShow}
          data={this.state.currentMarker}
        ></Person>
        <div className="alert-list">
          {
            this.state.alertData.map((item) => (
              <Alert 
                key={item.id} 
                message={this.formateAlertMsg(item)}
                type="warning" 
                showIcon 
                closable
                // closeText="View" 
                // onClose={() => this.onAlert(item)}
                closeText={
                  [
                    <Icon type="eye" key="eye" 
                      style={{fontSize: 16}} 
                      onClick={() => this.onAlert(item)} />,
                    <Icon type="close" key="close" 
                      style={{fontSize: 16, marginLeft: 6,marginTop: 3}} 
                      onClick={() => this.onClose(item)} />
                  ]
                }
              />
            ))
          }
          
          
        </div>
        
        <ReactAudioPlayer src={alertMp3} autoPlay={false} ref={(element) => { this.rap = element; }}/>
      </div>
    );
  }
}
