import React from "react";
import {
  message,
  Drawer,
  Icon,
  Tabs,
  Table,
  Button
} from "antd";
import { httpGet } from "../../http";
import "./Location.scss";
const TabPane = Tabs.TabPane;

class LocationRight extends React.Component {
  state = {
    collapse: false,
    data: [],
    current: Number(sessionStorage.getItem("alertCurrentPage")),
  };

  onPageChange = (Page) => {
    this.setState({
      current: Page,
    });
    sessionStorage.setItem("alertCurrentPage", Page);
  };

  componentDidMount() {
    this.getAlertList();
  }

  getAlertList = () => {
    httpGet("/api/v1/alert/get_alert_list")
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        this.setState({
          data: data.data,
        });
      })
      .catch((err) => {
        message.error("Interface error:get_alert_list");
      });
  };

  viewPosution = (item) => {
    localStorage.setItem("location", JSON.stringify(item));
    //this.props.changePosition();
    window.location.reload();
  };

  togglerContent = () => {
    const { collapse } = this.state;
    this.setState({ collapse: !collapse });
  };

  onClose = () => {
    this.setState({
      collapse: false,
    });
  };

  render() {
    const { collapse } = this.state;

    const columns = [
      {
        title: "Time",
        align: "center",
        dataIndex: "time",
        key: "time",
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Event",
        dataIndex: "message",
        key: "message",
      },
      {
        title: "Action",
        dataIndex: "id",
        align: "center",
        key: "id",
        render: (text, record) => (
          <span>
            <Button type="link" onClick={() => this.viewPosution(record)}>
              View Position
            </Button>
          </span>
        ),
      },
    ];

    return (
      <div>
        <Drawer
          width={500}
          onClose={this.onClose}
          visible={collapse}
          handler={
            <div className="handle" onClick={this.togglerContent}>
              <Icon
                type={collapse ? "right" : "left"}
                style={{
                  color: "#fff",
                  fontSize: 20,
                }}
              />
            </div>
          }
        >
          <Tabs defaultActiveKey="1">
            <TabPane tab="Alert" key="1">
              <Table
                rowKey={(record) => record.id}
                columns={columns}
                dataSource={this.state.data}
                pagination={{
                  current: this.state.current,
                  onChange: this.onPageChange,
                }}
              />
            </TabPane>
          </Tabs>
        </Drawer>
      </div>
    );
  }
}

export default LocationRight;
