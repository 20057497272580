import React from "react";
import { Table, message, Button, DatePicker } from "antd";
import { httpGet } from "../../http";
import { withRouter } from "react-router-dom";
import moment from "moment";

const { RangePicker } = DatePicker

class Inout extends React.Component {
  state = {
    data: [],
    pageSize: 10,
    startDate: '',
    endDate: '',
    signBtn: false 
  };

  componentDidMount() {
    this.getInoutRecords();
  }

  getInoutRecords = () => {
    httpGet("/api/v1/inout/get_inout_records")
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        this.setState({
          data: data.data,
        });
      })
      .catch((err) => {
        message.error("Interface error: get_inout_records");
      });
  };

  
  
  onShowSizeChange = (current, size) => {
    this.setState({
      pageSize: size
    })
  }

  onChange = (date, dateString) => {
    console.log(date, dateString);
    // endDate + 1
    const endDate = moment(date[1]).add(1, 'days').format('YYYY-MM-DD')
    
    this.setState({
      startDate: dateString[0],
      endDate: endDate
    })
  }
  onDownload = () => {
    if (this.state.startDate) {
      
      this.setState({
        signBtn: true
      })

      setTimeout(() => {
        this.setState({
          signBtn: false
        })
      }, 3 * 1000)
      message.success("Success.");
      let url = `/api/v1/inout/download_inout_records?start_time=${this.state.startDate}&end_time=${this.state.endDate}`;
      httpGet(url)
        .then(res => {
          return res.blob()
        })
        .then(res => {
          const link = document.createElement("a");
          let blob = new Blob([res], {
            type: ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'",
          });
          link.href = URL.createObjectURL(blob);
          link.download = `In_Out_Records-start_time=${this.state.startDate}-end_time=${this.state.endDate}.xlsx`;
          link.click();
          
        })
        .catch((err) => {
          message.error("Interface error: download_inout_records");
        });

    } else {
      message.error("Please select a date range.");
    }
  }

  columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
    },
    {
      title: "Event",
      dataIndex: "message",
      key: "message",
    }
    
  ];

  render() {
    return (
      <div className="box">
        <div className="table-operations">
          <RangePicker 
            onChange={ this.onChange } />

          <Button type="primary" 
            disabled={this.state.signBtn}
            onClick={() => this.onDownload()} style={{lineHeight: 1.5}}
            >Download
          </Button>
        </div>
        <Table
          rowKey={(record) => record.id}
          columns={this.columns}
          dataSource={this.state.data}
          pagination={{
            current: this.state.current,
            onChange: (Page) => {
              this.setState({
                current: Page,
              });
            },
            pageSize: this.state.pageSize,
              showSizeChanger: true,
              onShowSizeChange: this.onShowSizeChange
          }}
        ></Table>
      </div>
    );
  }
}

export default withRouter(Inout);
