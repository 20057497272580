import React from 'react';
import { Link, withRouter } from "react-router-dom";
import { Button, message } from 'antd';
import './Outline.scss';
import { httpPost } from "../../http"
import { Layout, Menu, Icon, Dropdown, Avatar } from 'antd';
const { Header, Sider, Content, Footer } = Layout;

const LeftSider = withRouter(({ history,collapsed,collapsedClose }) => {
    return (<Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
    >
        <div className="logo">
          <a href="/home">
            {collapsed ? <h1 className='small'>T2C</h1> : <h1 className='large'>T2C-CCCC</h1>}
          </a>               
        </div>
        <Menu theme="dark" mode="inline" defaultSelectedKeys={[history.location.pathname]}>
            <Menu.Item key="/home" onClick={collapsedClose}>
                <Link to="/home">
                  <Icon type="home" />
                  <span>Home</span>
                </Link>
            </Menu.Item>
            <Menu.Item key="/profiles" onClick={collapsedClose}>
                <Link to="/profiles">
                  <Icon type="ordered-list" />
                  <span>Registered Profiles</span>
                </Link>
            </Menu.Item>
            <Menu.Item key="/bind" onClick={collapsedClose}>
                <Link to="/bind">
                  <Icon type="link" />
                  <span>Bind</span>
                </Link>
            </Menu.Item>
            <Menu.Item key="/alert" onClick={collapsedClose}>
                <Link to="/alert">
                  <Icon type="alert" />
                  <span>Alert</span>
                </Link>
            </Menu.Item>
            <Menu.Item key="/inout" onClick={collapsedClose}>
                <Link to="/inout">
                  <Icon type="table" />
                  <span>In/Out</span>
                </Link>
            </Menu.Item>
        </Menu>
    </Sider>)
})

class App extends React.Component {
  
  state = {
    collapsed: true,
    defaultSelectedKeys:"home",
  };
  
  collapsedClose = () => {
      this.setState({
        collapsed: true,
      });
  };

  toggle = () => {
      this.setState({
        collapsed: !this.state.collapsed,
      });
  }

  changeSelectedKeys = (key) => {
    this.collapsedClose();
    this.setState({
      defaultSelectedKeys: key,
    });
  }

  exit = ()=>{
     httpPost("/auth/logout")
      .then(res => {
          return res.json();
      })
      .then(data => {
          console.log(data);
          if(data.code===0){
            sessionStorage.setItem("isLogin","0");
            this.props.history.push('./login');
          }
        })  
        .catch(err => {
            message.error('Interface error:logout');
        })
  }

  render() {
    const menu = (
        <Menu>
          <Menu.Item>
            <Button type="link" rel="noopener noreferrer" onClick={this.exit}>Logout</Button>
          </Menu.Item>
        </Menu>
    )
    const username = sessionStorage.getItem("username");

    return (
      <div className="customize">
        <Layout>
            <LeftSider collapsed={this.state.collapsed} collapsedClose={this.collapsedClose}/>
            <Layout>
                <Header style={{ background: '#f1f1f1', padding: 0}}>
                    <Icon
                      className="trigger"
                      type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'}
                      onClick={this.toggle}
                    />
                    <div className="user">
                      <Dropdown overlay={menu} placement="bottomLeft">
                          <div>
                            <Avatar src="" />
                            <span>{username}</span>
                          </div>
                      </Dropdown>
                    </div>
                </Header>
                <Content className="content">
                    {this.props.children}
                </Content>
                <Footer style={{ textAlign: 'center' }}>
                    T2C-CCCC ©2023 Created by Nextan
                </Footer>
            </Layout>
        </Layout>
      </div> 
    );
  }
}


export default withRouter(App);



