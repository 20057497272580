import React, { Component } from "react";
// import userIcon from "../../images/po.png";
import { Button, message } from "antd";
import { httpGet, httpPost } from "../../http";
import { withRouter } from "react-router-dom";

let map;
let all_polygon = [];
let old_polygon = [];
let geo_points;

class Geofencing extends Component {
  state = {
    drawerPersonShow: false,
  };

  componentDidMount() {
    //初始化地图
    let mapProp = {
      center: new window.google.maps.LatLng(1.354094, 103.991699),
      // center: new window.google.maps.LatLng(24.873507, 118.679722),
      zoom: 16,
      heading: -67,
      mapId: "a3c5a60ff715a444",
      disableDefaultUI: true,
      mapTypeId: window.google.maps.MapTypeId.HYBRID,
    };
    map = new window.window.google.maps.Map(this.refs.map, mapProp);

    this.getGenfence();
    this.drawing();
  }

  getGenfence = () => {
    httpGet("/api/v1/fence/get_geofence_list")
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        this.renderGenfence(data.data.geo_points);
      })
      .catch((err) => {
        message.error("Interface error:get_geofence_list");
      });
  };

  renderGenfence = (geofence_arr) => {
    for (let k = 0; k < geofence_arr.length; k++) {
      let paths = [];
      let geofence = geofence_arr[k];
      for (let i = 0; i < geofence.length; i++) {
        let point = geofence[i];
        paths.push(new window.google.maps.LatLng(point.G, point.K));
      }
      let polygon = new window.google.maps.Polygon({
        path: paths,
        strokeColor: "#000",
        strokeOpacity: 1,
        strokeWeight: 3,
        fillColor: "#000",
        fillOpacity: 0.2,
      });
      polygon.setMap(map);
      all_polygon.push(polygon);
    }
  };

  onpolygonComplete = (shape, drawingManager) => {
    let polygon = shape;
    polygon.setEditable(false);
    polygon.setMap(map);
    geo_points = [];
    for (let i = 0; i < all_polygon.length; i++) {
      let geo_points_item = [];
      let geo_points_one = all_polygon[i].getPath();
      for (let j = 0; j < geo_points_one.length; j++) {
        geo_points_item.push({
          G: geo_points_one.getAt(j).lat(),
          K: geo_points_one.getAt(j).lng(),
        });
      }
      geo_points.push(geo_points_item);
    }
  };

  drawing = () => {
    let that = this;
    let drawingManager = new window.google.maps.drawing.DrawingManager({
      drawingControl: true,
      drawingMode: window.google.maps.drawing.OverlayType.POLYGON,
      polylineOptions: {
        drawingModes: [window.google.maps.drawing.OverlayType.POLYGON],
      },
    });

    window.google.maps.event.addListener(
      drawingManager,
      "overlaycomplete",
      function (e) {
        var newShape;
        if (e.type === window.google.maps.drawing.OverlayType.POLYGON) {
          newShape = e.overlay;
          newShape.type = e.type;
          all_polygon.push(newShape);
          that.onpolygonComplete(newShape, drawingManager);
        }
      }
    );

    window.google.maps.event.addListener(map, "click", function (event) {
      drawingManager.setDrawingMode(
        window.google.maps.drawing.OverlayType.POLYGON
      );
      drawingManager.setMap(map);
    });
  };

  //重置回跟服务器同步的数据
  resetGeofence = () => {
    this.clearGeofence();
    all_polygon = old_polygon;
    for (let i = 0; i < all_polygon.length; i++) {
      all_polygon[i].setMap(map);
    }
  };

  //清楚所有的polygon
  clearGeofence = () => {
    for (let i = 0; i < all_polygon.length; i++) {
      all_polygon[i].setMap(null);
    }
    all_polygon = [];
    geo_points = [];
  };

  //保存polygon
  saveGeofence = () => {
    httpPost("/api/v1/fence/add_geofence", JSON.stringify({ geo_points }))
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        this.props.history.push("./home");
      })
      .catch((err) => {
        message.error("Interface error:add_geofence");
      });
  };

  render() {
    return (
      <div className="geofencing">
        <div className="btn">
          <Button type="primary" onClick={this.clearGeofence}>
            Clear
          </Button>
          <Button type="primary" onClick={this.resetGeofence}>
            Reset
          </Button>
          <Button type="primary" onClick={this.saveGeofence}>
            Save
          </Button>
        </div>
        <div
          id="googleMap"
          ref="map"
          style={{ width: "100%", height: "calc(100vh - 105px)" }}
        ></div>
      </div>
    );
  }
}

export default withRouter(Geofencing);
