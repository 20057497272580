import React, { Component } from "react";
// import moment from "moment";
import { message } from "antd";
import userIcon from "../../images/po.png";
import LocationRight from "./LocationRight.js";
import { httpGet, httpPost } from "../../http";
let map = {};

export default class Location extends Component {
  state = {
    line_marker: [],
    
  };
  componentDidMount() {
    //初始化地图
    if (!window.google) {
      message.error("Internet error:google map");
      return;
    }
    var mapProp = {
      center: new window.google.maps.LatLng(1.354094, 103.991699),
      // center: new window.google.maps.LatLng(24.873507, 118.679722),
      zoom: 16,
      heading: -67,
      mapId: "a3c5a60ff715a444",
      disableDefaultUI: true,
      mapTypeId: window.google.maps.MapTypeId.HYBRID,
    };
    map = new window.window.google.maps.Map(this.refs.map, mapProp);

    let location = localStorage.getItem("location");
    this.getGenfence();
    this.renderMarker(JSON.parse(location));
    this.getAlertLocations(JSON.parse(location));
  }

  animateCircle = (line) => {
    let count = 0;
    const total = this.state.line_marker.length;
    let speed = total > 0 ? Math.ceil(total / 50) * 20 : 20;

    const timer = window.setInterval(() => {
      count = (count + 1) % 200;

      const icons = line.get("icons");

      icons[0].offset = count / 2 + "%";
      line.set("icons", icons);

      if (count >= 199) {
        clearInterval(timer);
      }
    }, speed);
  };
  renderLine = () => {
    const lineSymbol = {
      path: window.google.maps.SymbolPath.CIRCLE,
      scale: 8,
      strokeColor: "#393",
    };

    const line = new window.google.maps.Polyline({
      path: this.state.line_marker,
      icons: [
        {
          icon: lineSymbol,
          offset: "0%",
        },
      ],
      strokeColor: "#FF0000",
      map: map,
    });

    this.animateCircle(line);
  };
  renderMarker = (data) => {
    const infoWindow = new window.google.maps.InfoWindow();

    let marker = new window.google.maps.Marker({
      position: new window.google.maps.LatLng(data.latitude, data.longitude),
      icon: userIcon,
      title: ` Name: ${data.name} <br/><br />
               Device ID: ${data.device_id} <br/><br />
               Time: ${data.time}`
    });
    marker.addListener("click", () => {
      infoWindow.close();
      infoWindow.setContent(marker.getTitle());
      infoWindow.open(marker.getMap(), marker);
    });
    marker.setMap(map);
    map.setCenter(new window.google.maps.LatLng(data.latitude, data.longitude));
  };

  renderGenfence = (genfence_arr) => {
    //地图围栏
   for (let k = 0; k < genfence_arr.length; k++) {
      if (k === (genfence_arr.length - 1)) {
        continue
      }
      let paths = [];
      let genfence = genfence_arr[k];

      for (let i = 0; i < genfence.length; i++) {
        let point = genfence[i];
        paths.push(new window.google.maps.LatLng(point.G, point.K));
      }
      let flightPath = new window.google.maps.Polygon({
        path: paths,
        strokeColor: "#f00",
        strokeOpacity: 0,
        strokeWeight: 2,
        fillColor: "#f00",
        fillOpacity: 0.4,
      });
      flightPath.setMap(map);
    };
  };

  getGenfence = () => {
    httpGet("/api/v1/fence/get_geofence_list")
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        this.renderGenfence(data.data.geo_points);
      })
      .catch((err) => {
        message.error("Interface error:get_geofence_list");
      });
  };

  getAlertLocations = (data) => {
    let submit_value = {
      time: data.time,
      device_id: data.device_id,
    };
    httpPost("/api/v1/device/get_alert_locations", JSON.stringify(submit_value))
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.code === 0) {
          this.setState({
            line_marker: data.data,
          });
          
          this.renderLine();
        } else {
          message.error(data.msg);
        }
      })
      .catch((err) => {
        message.error("Interface error: get_alert_locations");
      });
  };
  render() {
    return (
      <div className="location">
        <div
          id="googleMap"
          ref="map"
          style={{ width: "100%", height: "calc(100vh - 105px)" }}
        ></div>
        <LocationRight></LocationRight>
      </div>
    );
  }
}
