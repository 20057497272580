import React, { Component } from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import Login from '../User/Login.js'
import SubRouter from '../Router/SubRouter.js'




class Routers extends Component {
    render(){
      return (
          <BrowserRouter>
                <Switch>
                    <Route exact path="/login" component={ Login }></Route>
                    <Route path="/" component={ SubRouter }></Route>
                </Switch>
          </BrowserRouter>
        )
      }
}

export default Routers;